
const initialState = {
    country:"",
  };
  const selectCountryReducer = (state = initialState, action) => {
    switch (action.type) {
      case "selectCountry":
        return action.payload;
      default:
        return state;
    }
  };
  export default selectCountryReducer;
  
  
  
  