import { combineReducers } from 'redux'
import AuthReducer from './AuthReducer'
import filterReducer from './filterReducer'
import selectCountryReducer from './selectCountryReducer'
import setPermissionReducer from './setPermission'
import SidebarReducer from './SidebarReducer'
const reducers = combineReducers({
  filterR: filterReducer,
  country: selectCountryReducer,
  auth: AuthReducer,
  permission: setPermissionReducer,
  sidebarShow: SidebarReducer
})
export default reducers
